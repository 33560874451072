<script>
import Vue from "vue";
import Swal from "sweetalert2";
import Layout from "@/router/layouts/main";
import EventBus from "@/event-bus";
import { authComputed } from "@/state/helpers";
import Repository from "@/app/repository/repository-factory";
import Preloader from "@/components/preloader";
import ApplicationHeader from "@/components/applications/application-header";
import ApplicationPreview from "@/components/applications/application-preview";
import SidebarDetails from "@/components/applications/sidebar-details";
import SidebarAttachments from "@/components/applications/sidebar-attachments";
import Schedule from "@/components/applications/schedule";
import ScheduleApi from "@/components/applications/schedule-api";
import PageHeader from "@/components/page-header";
import { appService } from "@/app/service/appService";
import { roleService } from "@/app/service/roleService";

const PaymentApplicationRepository = Repository.get(
  "PaymentApplicationRepository"
);

export default {
  components: {
    Preloader,
    Layout,
    PageHeader,
    ApplicationHeader,
    ApplicationPreview,
    SidebarDetails,
    SidebarAttachments,
    Schedule,
    ScheduleApi,
  },
  data() {
    return {
      selectedFileTypeForGenerate: null,
      fileTypesArray: [],
      disabledBtn: false,
      preloader: false,
      itemData: null,
      scheduleData: null,
      loggedUser: null,
      title: "Szczegóły wniosku",
      items: [
        {
          text: "Strona główna",
          to: {
            name: "home",
          },
        },
        {
          text: "Szczegóły wniosku",
          active: true,
        },
      ],
    };
  },
  created() {
    if (this.$route.params.uuid) {
      this.loadData(this.$route.params.uuid);
    }
    EventBus.$on("refreshAppDetailsPage", (id) => {
      this.loadData(id);
    });
  },
  beforeDestroy() {
    EventBus.$off("refreshAppDetailsPage");
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
    isFinancialOperator() {
      return roleService.isFinancialOperator();
    },
    isSuperAccepting() {
      return roleService.isSuperAccepting();
    },
  },
  watch: {
    $route(to, from) {
      this.loadData(this.$route.params.uuid);
    },
  },
  methods: {
    ...authComputed,
    async loadData(id) {
      if (id) {
        this.preloader = true;
        if (this.isFinancialOperator || this.isAdmin) {
          try {
            const promise2 = await PaymentApplicationRepository.getBankFileTypes(
              id
            );
            this.fileTypesArray = promise2.data;

            let fileTypeSelect = this.fileTypesArray.filter((obj) => {
              return obj.isDefault === true && obj.isActive === true;
            });

            if (fileTypeSelect.length > 0) {
              this.selectedFileTypeForGenerate = fileTypeSelect[0].fileType;
            }
          } catch (error) {
            console.log(error);
          }
        }
        try {
          const promise = await PaymentApplicationRepository.get(id);

          if (
            promise.data.typeOfApplication === "Przelew skarbowy" &&
            promise.data.applicationSpecificData &&
            promise.data.applicationSpecificData.cycleId
          ) {
            try {
              const schedule = await PaymentApplicationRepository.getSchedule(
                id
              );
              await Promise.all([schedule]);
              this.scheduleData = schedule.data;
            } catch (error) {
              console.log(error);
            }
          }
          await Promise.all([promise]);
          this.itemData = promise.data;
          this.preloader = false;
        } catch (error) {
          this.preloader = false;
          console.log(error);
          this.$router.push({
            name: "error-404",
          });
        }
      }
    },
    compareUserToAcceptor(user) {
      return user === this.$store.getters["auth/loggedIn"].userAccountId;
    },
    checkStatus(s, a) {
      return appService.checkAppStatus(s, a);
    },
    sendApp(type) {
      switch (type) {
      case "higherAccept":
        this._status = 8;
        this._text = "Ręczna zmiana danych dostawcy została zaakceptowana!";
        this._hasReason = false;
        this._hasSelect = false;
        this._textReason = "Wybierz nowego akceptującego";
        this._textCancel =
          "Ręczna zmiana danych dostawcy nie została zaakceptowana";
        this._textBtn = "Autoryzuj zmianę danych dostawcy";
        break;
      case "higherDiscard":
        this._status = 9;
        this._text = "Ręczna zmiana danych dostawcy została odrzucona!";
        this._hasReason = true;
        this._hasSelect = false;
        this._textReason = "Podaj przyczynę odrzucenia wniosku";
        this._textCancel = "Wniosek nie został odrzucony!";
        this._textBtn = "Odrzuć wniosek";
        break;
      case "discardByFinancialOperator":
        this._status = 11;
        this._text = "Wniosek został odrzucony!";
        this._hasReason = true;
        this._hasSelect = false;
        this._textReason = "Podaj przyczynę odrzucenia wniosku";
        this._textCancel = "Wniosek nie został odrzucony!";
        this._textBtn = "Odrzuć wniosek";
        break;
      case "discardByAdmin":
        this._status = 12;
        this._text = "Wniosek został odrzucony!";
        this._hasReason = true;
        this._hasSelect = false;
        this._textReason = "Podaj przyczynę odrzucenia wniosku";
        this._textCancel = "Wniosek nie został odrzucony!";
        this._textBtn = "Odrzuć wniosek";
        break;
      default:
        break;
      }
      this.sendApplication(
        this._status,
        this._text,
        this._hasReason,
        this._hasSelect,
        this._textReason,
        this._textCancel,
        this._textBtn
      );
    },
    sendApplication(
      _status,
      _text,
      _hasReason,
      _hasSelect,
      _textReason,
      _textCancel,
      _textBtn
    ) {
      if (!_hasReason && !_hasSelect) {
        let payload = {
          applicationStatus: _status,
          version: this.itemData.version,
        };
        this.apiGo(payload, _text);
      } else if (_hasReason) {
        Swal.fire({
          title: _textReason,
          input: "text",
          showCancelButton: true,
          confirmButtonText: _textBtn,
          cancelButtonText: "Anuluj",
          showLoaderOnConfirm: true,
          confirmButtonColor: "#61aa3d",
          cancelButtonColor: "#e2001a",
          inputValidator: (reason) => {
            if (!reason) {
              return "Podaj przyczynę odrzucenia!";
            }
          },
          preConfirm: (reason) => {
            let payload = {
              applicationStatus: _status,
              statusMessage: reason,
              version: this.itemData.version,
            };
            this.apiGo(payload, _text);
          },
          allowOutsideClick: false,
        }).then((reason) => {
          if (reason.value) {
            "SUKCES!", _text, "success";
          } else if (reason.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Anulowano", _textCancel, "error");
            this.disabledBtn = false;
            this.preloader = false;
          }
        });
      } else if (_hasSelect) {
        Swal.fire({
          title: _textReason,
          input: "select",
          inputOptions: { icecream: "Ice cream" },
          inputPlaceholder: "Wybierz nowego akceptującego",
          showCancelButton: true,
          confirmButtonText: _textBtn,
          cancelButtonText: "Anuluj",
          showLoaderOnConfirm: true,
          confirmButtonColor: "#61aa3d",
          cancelButtonColor: "#e2001a",
          inputValidator: (reason) => {
            if (!reason) {
              return "Wybierz akceptującego!";
            }
          },
          preConfirm: (reason) => {
            let payload = {
              applicationStatus: _status,
              acceptorUserAccountId: reason,
              version: this.itemData.version,
            };
            this.apiGo(payload, _text);
          },
          allowOutsideClick: false,
        }).then((reason) => {
          if (reason.value) {
            "SUKCES!", _text, "success";
          } else if (reason.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Anulowano", _textCancel, "error");
            this.disabledBtn = false;
            this.preloader = false;
          }
        });
      }
    },
    apiGo(payload, _text) {
      this.disabledBtn = true;
      this.preloader = true;

      PaymentApplicationRepository.updateItem(payload, this.$route.params.uuid)
        .then(() => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: _text,
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({
                name: "home",
              });
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    generateBankFile(itemData) {
      this.disabledBtn = true;
      this.preloader = true;

      let params = null;

      if (
        this.selectedFileTypeForGenerate !== null &&
        this.selectedFileTypeForGenerate !== ""
      ) {
        params = "?fileType=" + this.selectedFileTypeForGenerate;
      }

      PaymentApplicationRepository.generateBankFile(params, [itemData])
        .then((res) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            html:
              "Plik bankowy został wygenerowany!<br/><br/><small>" +
              (res.data.fileLocation == "local"
                ? "Uwaga! Nie udało się zapisać pliku na dysku sieciowym!"
                : "Plik został zapisany na dysku sieciowym!") +
              "</small>",
            showConfirmButton: false,
            timer: 4000,
            onClose: () => {
              this.loadData(itemData.applicationId);
            },
          });
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    downloadBankFile(uuid, filename) {
      const cutFilename = filename.substring(filename.indexOf("/") + 1);
      PaymentApplicationRepository.downloadBankFile(uuid)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/*",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", cutFilename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          setTimeout(() => window.URL.revokeObjectURL(url), 200);
        })
        .catch((error) => {
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
  },
};
</script>

<template>
  <Layout>
    <Preloader v-if="preloader" />
    <PageHeader :title="title" :items="items" />

    <div class="row" v-if="itemData">
      <div class="col-md-8">
        <div class="card shadow-sm">
          <div class="card-body">
            <ApplicationHeader :itemData="itemData" :backUrl="{ name: 'home' }" />
            <hr />
            <ApplicationPreview :itemData="itemData" />
            <div class="row">
              <div class="col" v-if="isSuperAccepting && compareUserToAcceptor(itemData.higherAcceptorUserAccountId) && checkStatus(itemData.applicationStatus, [1])">
                <b-button block :disabled="disabledBtn" variant="success" v-on:click="sendApp('higherAccept')">Autoryzuj zmianę danych dostawcy</b-button>
              </div>
              <div class="col text-right">
                <b-button :disabled="disabledBtn" variant="info" class="mr-2" :to="{ name: 'applications-details-change-status', params: { uuid: itemData.applicationId }}" v-if="(isFinancialOperator || isAdmin) && checkStatus(itemData.applicationStatus, [3, 5])
                ">
                  Zmień status
                </b-button>
                <b-button :disabled="disabledBtn" variant="warning" class="mr-2" :to="{ name: 'applications-details-edit', params: { uuid: itemData.applicationId }}" v-if="(isFinancialOperator || isAdmin) && checkStatus(itemData.applicationStatus, [3, 5, 6, 7])
                ">
                  Edytuj wniosek
                </b-button>
                <b-button :disabled="disabledBtn" variant="danger" class="mr-2" v-on:click="sendApp('higherDiscard')" v-if="isSuperAccepting && compareUserToAcceptor(itemData.higherAcceptorUserAccountId) && checkStatus(itemData.applicationStatus, [1])
                ">
                  Odrzuć zmianę danych dostawcy
                </b-button>
                <b-button :disabled="disabledBtn" variant="danger" v-on:click="sendApp('discardByFinancialOperator')" v-if="(isFinancialOperator && !isAdmin) && checkStatus(itemData.applicationStatus, [1, 3, 5, 8])
                ">
                  Odrzuć wniosek
                </b-button>
                <b-button :disabled="disabledBtn" variant="danger" v-on:click="sendApp('discardByAdmin')" v-if="((!isFinancialOperator && isAdmin) || (isFinancialOperator && isAdmin)) && checkStatus(itemData.applicationStatus, [1, 3, 5, 8])
                ">
                  Odrzuć wniosek
                </b-button>
              </div>
            </div>
            <hr />
            <div class="row" v-if="(isFinancialOperator || isAdmin) && checkStatus(itemData.applicationStatus, [3, 5])">
              <div class="col-12" v-if="!selectedFileTypeForGenerate">
                <b-alert variant="warning" show fade>
                  <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Brak zdefiniowanego lub aktywnego szablonu pliku bankowego. Wygenerowanie pliku nie jest możliwe.
                </b-alert>
              </div>
              <div class="col">
                <b-button block :disabled="disabledBtn" v-if="selectedFileTypeForGenerate" variant="success" v-on:click="generateBankFile(itemData)">
                  <i class="mdi mdi-cogs font-size-12 mr-2"></i>Wygeneruj plik bankowy
                </b-button>
                <el-radio-group v-model="selectedFileTypeForGenerate" size="mini" class="mt-2">
                  <el-radio-button v-for="(file, index) in fileTypesArray" :label="file.fileType" :key="index" :disabled="!file.isActive">{{ file.fileType }}</el-radio-button>
                </el-radio-group>
              </div>
              <div class="col">
                <button v-if="itemData.bankFileName" class="btn btn-info btn-block mb-4" v-on:click="downloadBankFile(itemData.applicationId, itemData.bankFileName)">
                  <i class="mdi mdi-file-download-outline font-size-12 mr-2"></i>Pobierz ostatni wygenerowany plik bankowy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card shadow-sm">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="p-0">
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-playlist-star"></i>
                </span>
                <span class="d-none d-sm-inline-block">Dane wniosku</span>
              </template>
              <SidebarDetails :itemData="itemData" />
            </b-tab>
            <b-tab v-if="this.itemData.attachments && this.itemData.attachments.length > 0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-attachment"></i>
                </span>
                <el-badge :value="itemData.attachments.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Załączniki</span>
                </el-badge>
              </template>
              <SidebarAttachments :itemData="itemData" />
            </b-tab>
            <b-tab v-if="this.itemData.applicationSpecificData.isRecurring && (this.itemData.applicationSpecificData.schedule.length > 0 || scheduleData)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="mdi mdi-clock-check-outline"></i>
                </span>
                <el-badge :value="itemData.applicationSpecificData.schedule.length || scheduleData.length" :max="99" class="item">
                  <span class="d-none d-sm-inline-block">Harmonogram</span>
                </el-badge>
              </template>
              <Schedule :itemData="itemData" v-if="!scheduleData && this.itemData.applicationSpecificData.isRecurring && this.itemData.applicationSpecificData.schedule.length > 0" />
              <ScheduleApi :itemData="itemData" :scheduleData="scheduleData" v-else-if="scheduleData" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>